import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layouts";
import Hero from "../components/common/hero";
import Logo from "../components/common/logo";
import Intro from "../components/sustainability/Intro";
// import AtTranquilobay from "../components/sustainability/AtTranquilobay";
// import Neighborhood from "../components/sustainability/Neighborhood";
// import AroundBocas from "../components/sustainability/AroundBocas";
// import AroundWorld from "../components/sustainability/AroundWorld";
import Security from "../components/biosecurity/Security";
import DownloadLink from "../components/common/DownloadLink";
import RegenerativePledge from "../components/common/regenerativePledge";
import BreadCrumb from "../components/common/breadcrumb";
import Instagram from "../components/common/Instagram";
import Seo from "../components/seo";
import Faqs from "../components/home/faq";

export default function SustainabilityPractice({
  data: { sanitySustainabilityPage, sanitySitesettings },
}) {
  let seo;
  let hero;
  let breadcrumb;
  let regenerative;
  let faq;

  sanitySustainabilityPage?.pageBuilder?.forEach((element) => {
    if (element._type === "seo") {
      seo = element;
    } else if (element._type === "heroSection") {
      hero = element;
    } else if (element._type === "breadcrumb") {
      breadcrumb = element;
    } else if (element._type === "regenerative") {
      regenerative = element;
    } else if (element._type === "faq") {
      faq = element;
    }
  });
  return (
    <Layout>
      <Seo data={seo} />
      <Hero heroSection={hero} h2Size="text-3xl sm:text-[60px]" h2P="pb-7" />
      <Logo logo={sanitySitesettings?.logo} />
      <div className="tb-container py-5 px-[10px]">
        <div className="w-[90%] mx-auto">
          {sanitySustainabilityPage?._rawIntro && (
            <Intro data={sanitySustainabilityPage._rawIntro} />
          )}
        </div>
      </div>
      {sanitySustainabilityPage?.bioSections?.map((item, index) => {
        return <Security data={item} key={index} />;
      })}
      <br />{" "}
      {sanitySustainabilityPage?.externalLink && (
        <DownloadLink
          text={sanitySustainabilityPage.externalLink.exText}
          link={sanitySustainabilityPage.externalLink.url}
        />
      )}
      {faq?.heading && faq?.faqList.length > 0 && <Faqs data={faq} />}
      {breadcrumb && <BreadCrumb path={breadcrumb} />}
      <Instagram />
      {regenerative && (
        <div>
          <RegenerativePledge data={regenerative} />
          <Logo logo={sanitySitesettings.logo} />
        </div>
      )}
    </Layout>
  );
}

export const query = graphql`
  query {
    sanitySustainabilityPage {
      pageBuilder {
        ... on SanitySeo {
          _type
          description
          keywords
          ldSchema
          pagehandle
          title
        }
        ... on SanityFaq {
          _type
          heading
          faqList {
            question
            _rawAnswer
          }
        }
        ... on SanityHeroSection {
          _type
          heading1
          heading2
          alignment
          ctaButton {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          bgImages {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
        }
        ... on SanityBreadcrumb {
          _type
          breadLink {
            breadPath
            nameLink
          }
          sectionBg {
            asset {
              url
            }
          }
        }
        ... on SanityRegenerative {
          _type
          heading
          _rawContent
          _rawAuthor
          sectionBg {
            asset {
              url
            }
          }
        }
      }
      _rawIntro
      bioSections {
        bgSize
        bgRepeat
        columns
        heading
        height
        overlay
        svg
        textColor
        sectionBG {
          asset {
            url
          }
        }
        columnItem {
          _rawCol
        }
      }
      externalLink {
        exText
        url
      }
    }
    sanitySitesettings {
      logo {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;
